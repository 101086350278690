/* 設定 .css-19r6kue-MuiContainer-root 類的樣式 */
.css-19r6kue-MuiContainer-root {
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    border-radius: 16px;
    /* 背景顏色設為白色 */
    background-color: #ffffff;
    /* 設置陰影 */
    box-shadow: 7;
}

/* 設定 .css-nufsav 類的背景顏色為白色 */
.css-nufsav {
    background-color: #ffffff;
}

/* 設定 .css-dxfs78 類的背景顏色為由上到下漸層從深綠色到稍深的淺綠色 */
.css-dxfs78 {
    background: linear-gradient(to bottom, #4a9c6f, #7ed4a6);
}

/* 設定 .simplebar-wrapper 類的背景顏色為由上到下漸層從深綠色到稍深的淺綠色 */
.simplebar-wrapper {
background: linear-gradient(to bottom, #4a9c6f, #7ed4a6);
}
/* 設定 .css-arryz2-MuiButtonBase-root-MuiListItemButton-root 類的文字顏色為白色 */
.css-arryz2-MuiButtonBase-root-MuiListItemButton-root {
    color: #ffffff;
}

/* 設定 .css-wzaslu-MuiButtonBase-root-MuiListItemButton-root 類的文字顏色為綠色 */
.css-wzaslu-MuiButtonBase-root-MuiListItemButton-root {
    color: #66e85a;
}