
.limitList  {
    position: absolute;
    bottom:0px;
    left:20px;
    background-color: #3b82f6;
    width: 120px;
    color:#ffffff;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.limitList:hover {
    background-color: #3b82f6;
}

.readerCountTips_red {
    position: absolute;
    top:20px;
    right:130px;
    background-color: #ca2d40;
    width: 30px;
    border: 1px solid #000001;
    color:#ffffff;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.readerCountTips_red1 {
    position: absolute;
    top:20px;
    right:0;
    background-color: #f4f4f4;
    width: 130px;
    border: 1px solid #000001;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.readerCountTips_orange {
    position: absolute;
    top:45px;
    right:130px;
    background-color: #fdd226;
    width: 30px;
    border: 1px solid #000001;
    color:#ffffff;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.readerCountTips_orange1 {
    position: absolute;
    top:45px;
    right:0;
    background-color: #f4f4f4;
    width: 130px;
    border: 1px solid #000001;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.readerCountTips_green {
    position: absolute;
    top:70px;
    right:130px;
    background-color: #26ac23;
    width: 30px;
    border: 1px solid #000001;
    color:#ffffff;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.readerCountTips_green1 {
    position: absolute;
    top:70px;
    right:0;
    background-color: #f4f4f4;
    width: 130px;
    border: 1px solid #000001;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.readerCountTips_grey {
    position: absolute;
    top:95px;
    right:130px;
    background-color: #666666;
    width: 30px;
    border: 1px solid #000001;
    color:#ffffff;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.readerCountTips_grey1 {
    position: absolute;
    top:95px;
    right:0;
    background-color: #f4f4f4;
    width: 130px;
    border: 1px solid #000001;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
