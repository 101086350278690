.boxText{
    position:relative;
    width: 920px;
    border:1px solid rgb(0, 0, 0);
}
.boxText::before{
    content:attr(title);
    position:absolute;
    left:5%;
    transform:translateX(-50%);
    -webkit-transform:translate(-50%,-50%);
    padding:0 10px;
    background-color:#fff;
}


.temperatureTitle {
    color: #ffffff;
    text-align: left;
    font-size: 3.2vh;
}
.temperatureContext {
    color: #ffffff;
    text-align: left;
    font-size: 3.2vh;
}
.weatherTitle {
    color: #ffffff;
    text-align: left;
    font-size: 3.2vh;
}

.weatherBlock {
    /* padding-left:10px !important; */
    /* padding-bottom: 12px !important; */
}

.Title {
    height: calc(100% - 60px);
    padding-left: 10.1vw;
    color: #ffffff;
    font-size: 5.2vh;
    text-align: center;
}

.Title1 {
    height: calc(100% - 60px);
    padding-left: 10vw;
    color: #ffffff;
    font-size: 5.2vh;
    text-align: center;
}

.clock {
    font-family: "Share Tech Mono", monospace;
    text-align: center;
    color: #fff;
    text-align: left;
    margin-left: 20px;
    margin-top: -2.1vh;
    /* text-shadow: 0 0 20px #0aafe6, 0 0 20px rgba(10, 175, 230, 0); */
}

.clockTime {
    letter-spacing: 0.05em;
    font-size: 2.5vw;
    /* font-size: 45px; */
}

.clockDate {
    letter-spacing: 0.1em;
    font-size: 1.2vw;
    margin-left: 20px;
    /* font-size: 24px; */
}

.textTitle {
    color: #b9d6ff;
    font-size: 2.9vh;
    text-align: center;
}

.textContent {
    color: #ffffff;
    font-size: 4.0vh;
    text-align: right;
}
.textContentG {
    color: #5cf320;
    font-size: 4.0vh;
    text-align: left;
}
.textContentR {
    color: #f33920;
    font-size: 4.0vh;
    text-align: left;
}
.textContentGP {
    color: #5cf320;
    font-size: 4.0vh;
    text-align: center;
}
.textContentRP {
    color: #f33920;
    font-size: 4.0vh;
    text-align: center;
}

.block1 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.whfwh4s2 {
    z-index: 320;
    box-sizing: border-box;
    margin: 0;
    padding-left: 0px !important;
    padding-top: 0px !important;
    background-size: 0.5px 10px, 10px 1px, 1px 10px, 10px 1px;    
    position:relative;
    border:1px solid rgb(0, 0, 0);
    width: 100%;
}

.whfwh4s1 {
    z-index: 320;
    box-sizing: border-box;
    margin: 0;
    padding-left: 0px !important;
    padding-top: 0px !important;
    box-shadow: rgba(0, 202, 253, 0.5) 0px 0px 20px inset;
    background: linear-gradient(to left, #00adfd, #00adfd) left top no-repeat,
        linear-gradient(to bottom, #00adfd, #00adfd) left top no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) right top no-repeat,
        linear-gradient(to bottom, #00adfd, #00adfd) right top no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) left bottom no-repeat,
        linear-gradient(to bottom, #00adfd, #00adfd) left bottom no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) right bottom no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) right bottom no-repeat;
    background-size: 0.5px 10px, 10px 1px, 1px 10px, 10px 1px;
    
    position:relative;
    border:1px solid rgb(0, 0, 0);
    width: 100%;
}
.whfwh4s2 {
    z-index: 320;
    box-sizing: border-box;
    margin-left: 0.5vw;
    padding-left: 0px !important;
    padding-top: 0px !important;
    box-shadow: rgba(0, 202, 253, 0.5) 0px 0px 20px inset;
    background: linear-gradient(to left, #00adfd, #00adfd) left top no-repeat,
        linear-gradient(to bottom, #00adfd, #00adfd) left top no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) right top no-repeat,
        linear-gradient(to bottom, #00adfd, #00adfd) right top no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) left bottom no-repeat,
        linear-gradient(to bottom, #00adfd, #00adfd) left bottom no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) right bottom no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) right bottom no-repeat;
    background-size: 0.5px 10px, 10px 1px, 1px 10px, 10px 1px;
    
    position:relative;
    border:1px solid rgb(0, 0, 0);
    width: 100%;
}

.whfwh4s1::before{
    font-size: 2.4vh;
    content:attr(title);
    position:absolute;
    transform:translateX(50%);
    -webkit-transform:translate(15%,-50%);
    padding:0 10px;
    background-color:#ffffff00;
    color:#fff;
}