.box{
    position:relative;
    width: 1200px;
    border:1px solid rgb(0, 0, 0);
}
.box::before{
    content:attr(title);
    position:absolute;
    left:5%;
    transform:translateX(-50%);
    -webkit-transform:translate(-50%,-50%);
    padding:0 10px;
    background-color:#fff;
}


.Title {
    height: calc(100% - 60px);
    padding-top: 8px;
    color: #ffffff;
    font-size: 2.2vw;
    text-align: center;
}

.clock {
    font-family: "Share Tech Mono", monospace;
    text-align: center;
    color: #daf6ff;
    margin-top: -1.8vw;
    text-shadow: 0 0 20px #0aafe6, 0 0 20px rgba(10, 175, 230, 0);
}

.clockTime {
    letter-spacing: 0.05em;
    font-size: 2.7vw;
    /* font-size: 45px; */
}

.clockDate {
    letter-spacing: 0.1em;
    font-size: 1.4vw;
    /* font-size: 24px; */
}

.textTitle {
    height: calc(100% - 60px);
    color: #2094f3;
    font-size: 1.4vw;
    text-align: center;
}

.textContent {
    height: calc(100% - 60px);
    color: #f37f20;
    font-size: 1.4vw;
    text-align: center;
}


.whfwh4s1 {
    z-index: 320;
    box-sizing: border-box;
    margin: 0;
    padding-left: 0px !important;
    padding-top: 0px !important;
    box-shadow: rgba(0, 202, 253, 0.5) 0px 0px 20px inset;
    background: linear-gradient(to left, #00adfd, #00adfd) left top no-repeat,
        linear-gradient(to bottom, #00adfd, #00adfd) left top no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) right top no-repeat,
        linear-gradient(to bottom, #00adfd, #00adfd) right top no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) left bottom no-repeat,
        linear-gradient(to bottom, #00adfd, #00adfd) left bottom no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) right bottom no-repeat,
        linear-gradient(to left, #00adfd, #00adfd) right bottom no-repeat;
    background-size: 0.5px 10px, 10px 1px, 1px 10px, 10px 1px;
}